import { useCallback, useEffect, useState } from 'react'
import { CookieSerializeOptions } from 'cookie'
import cookie from 'react-cookies'

import { API } from '../../services/api'
import { loading } from '../../utils/loading'

import Logo from '../../assets/logomarca.png'

import { Container } from './styles'

import { 
	Input, 
	Button, 
	Segment,
	Icon,
	Message,
	Checkbox
} from 'semantic-ui-react'

export interface ILogin {
	sessionId: number
	accessToken: string
	message: string
	error: number
	userProfile: 'ADMIN' | 'MNTOR' | 'STDNT' | 'TALNT'
}

export const Login: React.FC = () => {
	const [hasError, sethasError] = useState(false)
	const [user, setUser] = useState('')
	const [password, setPassword] = useState('')
	const [remember, setRemember] = useState(false)
	
	const getSavedEmail = useCallback(() => {
		const storedEmail = localStorage.getItem('login-email')
		if( storedEmail !== null ) {
			setUser(storedEmail)
			setRemember(true)
		}
	},[])

	useEffect(() => {
		cookie.remove('session-id')
		cookie.remove('access-token')
		getSavedEmail()
	},[])

	const cookieConfig: CookieSerializeOptions = {
		path: '/', 
		domain: process.env.NODE_ENV == 'production' ?	'pragma.school' : undefined
	}

	const login = useCallback((event) => {
		event.preventDefault()
		loading('flex')
		
		API.post<ILogin>(
			process.env.REACT_APP_API_DOMAIN + '/login',
			{
				email: user.trim(),
				password: password.trim()
			}
		).then(response => {
			if(response.status === 200 && response.data.error === 0) {
				cookie.save('session-id',  response.data.sessionId, cookieConfig)
				cookie.save('access-token', response.data.accessToken, cookieConfig)
				
				remember ?
					localStorage.setItem('login-email', user )
					:
					localStorage.removeItem('login-email')
	
				loading('none')
				
				switch (response.data.userProfile) {
				case 'ADMIN':
					window.location.href = process.env.REACT_APP_ADMIN_DOMAIN || '#'
					break
				case 'MNTOR':
					window.location.href = process.env.REACT_APP_MENTOR_DOMAIN || '#'
					break
				case 'STDNT':
					window.location.href = process.env.REACT_APP_STUDENT_DOMAIN || '#'
					break
				case 'TALNT':
					window.location.href = process.env.REACT_APP_TALENT_DOMAIN || '#'
					break
				default:
					break
				}
			} else {
				loading('none')
				sethasError(true)
				cookie.remove('session-id', cookieConfig)
				cookie.remove('access-token', cookieConfig)
			}
		})
			.catch(err => console.error(err)
			)
	},[cookieConfig, loading, remember] )

	document.title = 'Login :: My Pragma'

	return (
		<Container>
			<div className="CENTER animated fadeIn">
				<div className="CONTAINER">
					<img src={Logo} alt="Pragma" className="LOGO" />
					<Segment.Group>
						<Segment className="BOX_CONTENT">
							<form onSubmit={login}>
								<h2 className="BOX_TITLE">Acesse a sua conta</h2>
								{hasError && 
								<Message
									negative
									header='Algo deu errado.'
									content='Verifique as informações.'
									className="animated fadeIn"
								/>
								}
								<Input
									icon='at'
									size='big'
									fluid placeholder="Email"
									className="INPUTS"
									value={user}
									onChange={(event) => setUser(event.target.value)} 
								/>

								<Input
									icon='lock'
									size='big'
									fluid placeholder="Senha"
									type="password" className="INPUTS"
									value={password}
									onChange={(event) => setPassword(event.target.value)}
								/>

								<Checkbox
									checked={remember}
									label='Lembrar meu email'
									style={{width: '100%', textAlign: 'left', marginBottom: '10px', fontSize:'13px'}}
									onChange={(event)=> setRemember(event.currentTarget.checked)}
								/>
								<Button
									onClick={login}
									size='big'
									color='black'
									fluid
									icon
									labelPosition='right'>
									<Icon name='arrow right' /> Entrar
								</Button>
							</form>
						</Segment>
						<Segment className="BOX_FOOTER">
							<a href="/password-recovery" className="FOOTER_LINKS">Esqueci minha senha</a>
						</Segment>
					</Segment.Group>
					<div className="FOOTER">PragmaSchool &copy; {new Date().getFullYear()} - Todos os direitos reservados</div>
				</div>
			</div>
		</Container>
	)
}