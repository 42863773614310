import styled from 'styled-components'

export const Container = styled.div`
	background-color: #f0f0f0;

	.CENTER {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
	}

	.LOGIN {
		background-color: black;
	}

	.BOX_CONTENT {
		padding: 10px 40px 30px 40px !important;
	}

	.BOX_TITLE {
		font-size: 25px;
		font-weight: 400;
		line-height: 30px;
		padding:15px 0 15px 0;
		letter-spacing: -0.5px;
	}

	.BOX_FOOTER {
		background-color: #f5f5f5 !important;
	}

	.CONTAINER {
		max-width: 450px;
		width: 100%;
		box-sizing: border-box;
		padding: 10px;
		text-align: center;
	}

	.LOGO {
		max-width: 90px;
		margin-bottom:30px;
	}

	.INPUTS {
		margin-bottom:10px;
	}

	.FOOTER_LINKS {
		display: block;
		font-size: 16px;
		line-height:30px;
	}

	.FOOTER {
		margin-top:20px;
		font-size: 12px;
		line-height:14px;
		color: #999;
	}

	.TEMP_TAPUME {
		height: 100%;
		width: 100%;
		min-height:267px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.TEMP_TAPUME_CONTENT {
		text-align: center;
	}

	.TEMP_TAPUME_CONTENT h1, .TEMP_TAPUME_CONTENT h4 {
		margin:15px 0 !important;
	}

	@media (max-width: 700px) {

		.BOX_TITLE {
			font-size: 25px;
			font-weight: 400;
			line-height: 30px;
			padding:10px 0 10px 0;
		}
			
		.BOX_CONTENT {
			padding: 10px 20px 20px 20px !important;
		}

	}
`

