import { Route, Switch, Redirect } from 'react-router-dom'
import { Login } from '../pages/Login'
import { PasswordResetPage } from '../pages/Password/PasswordResetPage'
import { PasswordRecoveryPage } from '../pages/Password/PasswordRecoveryPage'

export const Routes: React.FC = () => (
	<Switch>
		<Route exact path="/" component={Login} />
		<Route path="/password-recovery" component={PasswordRecoveryPage}/>
		<Route path="/create-password/:id/:token" component={PasswordResetPage}/>
		<Route path="/reset-password/:id/:token" component={PasswordResetPage}/>
		<Route>{/* 404 route */}<Redirect to="/" /></Route>
	</Switch>
)