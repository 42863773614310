import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { Routes } from './routes/routes'

import { GlobalStyle } from './styles/global'

import { Loading } from './utils/loading'

import 'semantic-ui-css/semantic.min.css'

export const App: React.FC = () => {
	return (
		<BrowserRouter>
			<Loading />
			<Routes />
			<GlobalStyle />
		</BrowserRouter>
	)
}
