import Load from '../assets/loading.svg'

interface IProps {
  styled?: string
}

export const loading = (load: string): void => {
	document.getElementById('loading')?.setAttribute('style', `display:${load};`)
}

export const Loading: React.FC<IProps> = styled => {
	return (
		<div id="loading" style={{ display: `${styled}` }}>
			<img className="image" src={Load} alt="loading" />
		</div>
	)
}