import { useState } from 'react'
import { Link } from 'react-router-dom'

import { 
	Input, 
	Button, 
	Segment,
	Icon,
	Header,
	Message
} from 'semantic-ui-react'

import { API } from '../../services/api'
import { loading } from '../../utils/loading'
import { validateEmail } from '../../utils/validateEmail'
import { Container } from '../Login/styles'

import Logo from '../../assets/logomarca.png'

export const PasswordRecoveryPage: React.FC = () => {
	const [hasError, setHasError] = useState(false)
	const [user, setUser] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [finishSendEmail, setFinishSendEmail] = useState(false)

	const sendInstructions = async() => {
		loading('flex')
		if(user > ' ') {
			if(validateEmail(user)){
				setHasError(false)
				const { data } = await API.post(
					process.env.REACT_APP_API_DOMAIN + '/send-password',
					{
						email: user
					}
				)

				if(typeof data.error === 'undefined' || data.error === 0){
					setHasError(false)
					setFinishSendEmail(true)
				} else {
					setHasError(false)
					switch(data.error) {
					case 404: setErrorMessage('Email não encontrado')
						break
					default: setErrorMessage('Erro inesperado.')
						break
					}
				}
			} else {
				setHasError(true)
				setErrorMessage('Email Inválido')
			}
		} else {
			setHasError(true)
			setErrorMessage('Preencha o Email')
		}
		loading('none')
	}

	document.title = 'Recuperar Senha :: My Pragma'
	
	return (
		<Container>
			<div className="CENTER animated fadeIn">
				<div className="CONTAINER">
					<img src={Logo} alt="Pragma" className="LOGO" />
					<Segment.Group>
						<Segment className="BOX_CONTENT">
							<h2 className="BOX_TITLE">Recuperar Senha</h2>
							{hasError &&
								<Message
									negative
									header='Ops!'
									content={errorMessage}
									className="animated fadeIn"
								/>
							}
							{
								finishSendEmail ? 
									<span className="animated fadeIn">
										<Icon name="mail" size="huge" className="animated tada delay-2s" />
										<Header as="h3">O link de recuperação foi encaminhado ao email informado.</Header>
										<p>Verifique seu email.</p>
									</span>
									:
									<span className="animated fadeIn delay-1s">
										<Input
											icon='at' size='big'
											fluid placeholder="Email"
											className="INPUTS"
											value={user}
											onChange={(event)=> setUser(event.target.value)}
										/>
										<Button onClick={sendInstructions}
											size='big'fluid color='black'
											icon labelPosition='right'>
											<Icon name='arrow right' />
											Enviar Instruções
										</Button>
									</span>
							}
						</Segment>
						<Segment className="BOX_FOOTER">
							<Link to="/" className="FOOTER_LINKS">Voltar ao login</Link>
						</Segment>
					</Segment.Group>
					<div className="FOOTER">PragmaSchool &copy; {new Date().getFullYear()} - Todos os direitos reservados</div>
				</div>
			</div>
		</Container>
	)
}