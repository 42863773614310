import { useCallback, useState } from 'react'
import { CookieSerializeOptions } from 'cookie'
import { useParams, Link } from 'react-router-dom'
import cookie from 'react-cookies'

import { 
	Input, 
	Button, 
	Segment,
	Icon,
	Header,
	Message
} from 'semantic-ui-react'

import { API } from '../../services/api'
import { loading } from '../../utils/loading'

import Logo from '../../assets/logomarca.png'

import { Container } from '../Login/styles'
import { ILogin } from '../Login'

type IPWD = ILogin

interface IParams {
	id: string
	token: string
}

export const PasswordResetPage: React.FC = () => {
	const params = useParams<IParams>()
	const id = parseInt(params.id)
	const token = params.token

	const [hasError, setHasError] = useState(false)
	const [password, setPassword] = useState('')
	const [confirmation, setConfirmation] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [finishRecovery, setFinishRecovery] = useState(false)

	const cookieConfig: CookieSerializeOptions = {
		path: '/', 
		domain: process.env.NODE_ENV == 'production' ?	'pragma.school' : undefined
	}

	const sendInstructions= useCallback(async () => {
		loading('flex')
		if(id > 0 && token > ' '){
			if(password > ' ' && confirmation > ' '){
				if(password === confirmation) {
					setHasError(false)
					const { data } = await API.post<IPWD>(
						process.env.REACT_APP_API_DOMAIN + '/reset-password',
						{
							id: id,
							token: token,
							password: password
						}
					)
						
					if(typeof data.error === 'undefined' || data.error === 0){
						setHasError(false)
						cookie.save('session-id',  data.sessionId, cookieConfig)
						cookie.save('access-token', data.accessToken, cookieConfig)
							
						if(data.userProfile === 'ADMIN') {
							window.location.href = process.env.REACT_APP_ADMIN_DOMAIN || '#'
						} else {
							window.location.href = process.env.REACT_APP_STUDENT_DOMAIN || '#'
						}
						setFinishRecovery(true)
					} else {
						setHasError(true)
						switch(data.error){
						case 593: setErrorMessage('Requisição expirada ou inválida'); break
						case 403: setErrorMessage('Senha Inválida. A senha deve ter ao menos 6 digitos'); break
						default: setErrorMessage('Erro inesperado')
						}
					}
				} else {
					setHasError(true)
					setErrorMessage('Senhas não conferem')
				}
			}else{
				setHasError(true)
				setErrorMessage('Preencha todos os campos')
			}
		}else{
			setHasError(true)
			setErrorMessage('URL Inválida')
		}
		loading('none')
	},[loading, API, confirmation, password])

	document.title = 'Redefinir Senha :: My Pragma'
	return (
		<Container>
			<div className="CENTER animated fadeIn">
				<div className="CONTAINER">
					<img src={Logo} alt="Pragma" className="LOGO" />
					<Segment.Group>
						<Segment className="BOX_CONTENT">
							<h2 className="BOX_TITLE">Redefinir Senha</h2>
							{hasError ? 
								<Message
									negative
									header='Ops!'
									content={errorMessage}
									className="animated fadeIn" />
								:
								''
							}
							{finishRecovery ? 
								<Header as="h4">Entrando...</Header>
								: 
								<>
									<Input
										icon='lock' size='big'
										fluid placeholder="Nova Senha"
										type="password" className="INPUTS"
										value={password}
										onChange={(event) => setPassword(event.target.value)}
									/>
									<Input
										icon='lock' size='big'
										fluid placeholder="Confirmar Nova Senha"
										type="password" className="INPUTS"
										value={confirmation}
										onChange={(event) => {setConfirmation(event.target.value)}}
									/>
									<Button onClick={sendInstructions} size='big' fluid color='black' icon labelPosition='right'>
										<Icon name="arrow right" />
										Redefinir
									</Button>
								</>
							}							
						</Segment>
						<Segment className="BOX_FOOTER">
							<Link to="/" className="FOOTER_LINKS">Voltar ao login</Link>
						</Segment>
					</Segment.Group>
					<div className="FOOTER">PragmaSchool &copy; {new Date().getFullYear()} - Todos os direitos reservados</div>
				</div>
			</div>
		</Container>
	)
}